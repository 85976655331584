import React from "react";
import { Route, Redirect } from 'react-router-dom';
import {isUserLoggedIn} from '../utils/authentication'

const LoggedInRoute = ({ exact, path, component }) => {
	if(!isUserLoggedIn()) return <Redirect from={path} to="/login"/>
    return <Route exact={exact} path={path} component={component} />;
};

export default LoggedInRoute;
