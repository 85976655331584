import actionsDefaults from "../actionsDefaults";

const reducer = (state = false, action) => {
	switch (action.type) {
		case actionsDefaults.SPINNER_ON:
			return action.payload;
		case actionsDefaults.SPINNER_OFF:
			return action.payload;
		default:
			return state;
	}
};

export default reducer;
