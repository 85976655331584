import { combineReducers } from "redux";

import spinnerReducer from "./spinnerReducer";
import sideNavReducer from './sideNavReducer'

const reducers = combineReducers({
	spinner: spinnerReducer,
	sideNav: sideNavReducer,
});

export default reducers;
