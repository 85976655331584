import React from 'react';

import './Spinner.css';

export default function Spinner() {
	return (
		<div className={`spinner`}>
				<div>
					<div className={`spinner-item`} />
					<div className={`spinner-item`} />
					<div className={`spinner-item`} />
				</div>
		</div>
	);
}
