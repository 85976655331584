import React, { Suspense, lazy } from "react";
import { Router, Switch, Route, HashRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

// import { useDispatch } from "react-redux";
// import { bindActionCreators } from "redux";
// import { spinnerActionsCreator, sideNavActionsCreator } from "./redux/index";

import { removeAdminUser } from "./utils/authentication";

import BeforeLoginRoute from "./routes/BeforeLoginRoute";
import LoggedInRoute from "./routes/LoggedInRoute";

import Spinner from "./components/UI/Spinner/Spinner";
import axios from "axios";

const Login = lazy(() => import("./pages/Login.jsx"));
const Admin = lazy(() => import("./pages/Admin"));

// * ***************************************************
const ScrollToTop = () => {
    window.scrollTo(0, 0);
};

const history = createBrowserHistory();

export const logout = () => {
    removeAdminUser();
    window.location.reload(false).then(history.replace("/"));
};

const children = (MyComponent) => {
    window.scrollTo(0, 0);
    return <MyComponent history={history} logout={logout} />;
};

// * ***************************************************

const App = () => {
    // const dispatch = useDispatch();
    // const { turnSpinnerOff } = bindActionCreators(spinnerActionsCreator, dispatch);
    // const { closeSideNav } = bindActionCreators(sideNavActionsCreator, dispatch);

    axios.interceptors.response.use(
        (response) => {
            if (response.data === "token error" || response.data === "role error" || response.data === "error role") {
                logout();
            }
            return response;
        },
        (error) => {
            console.log(error);
            // turnSpinnerOff();
            return Promise.reject(error);
        },
    );

    axios.defaults.baseURL = "https://admin.menabev.com/backend/api/";

    return (
        <HashRouter onChange={ScrollToTop} history={history}>
            <Suspense
                fallback={
                    <span className="body-height d-flex justify-content-center align-items-center">
                        <Spinner />
                    </span>
                }
            >
                <Switch>
                    <BeforeLoginRoute exact path="/login" component={() => children(Login)} />
                    <LoggedInRoute path="/" component={() => children(Admin)} />
                    <Route component={() => children(Admin)} />
                </Switch>
            </Suspense>
        </HashRouter>
    );
};

export default App;
