import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import {isUserLoggedIn} from '../utils/authentication'

const BeforeLoginRoute = ({ exact, path, component }) => {
    if(isUserLoggedIn()) return <Redirect from={path} to="/admin"/>
    return <Route exact={exact} path={path} component={component} />;
}

export default BeforeLoginRoute
