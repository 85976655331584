 let storage = {};
const storageInfo = localStorage.getItem("admin-user");

if (storageInfo) {
    storage = JSON.parse(decodeURIComponent(atob(storageInfo)));
}

export const setAdminUser = (value) => {
	storage = value;
	localStorage.setItem("admin-user", btoa(encodeURIComponent(JSON.stringify(storage))));
};

export const getAdminUser = () => {
	return storage;
};

export const removeAdminUser = () => {
	localStorage.clear();
	storage = {};
};

export const isUserLoggedIn = () => {
	return (Object.keys(getAdminUser()).length > 0);
}
