import actionsDefaults from "../actionsDefaults";

const reducer = (state = true, action) => {
	switch (action.type) {
		case actionsDefaults.SIDENAV_OPEN:
			return action.payload;
		case actionsDefaults.SIDENAV_CLOSE:
			return action.payload;
		default:
			return state;
	}
};

export default reducer;
